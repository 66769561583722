// export function generateUrl({
//   mediaSource,
//   campaign,
//   campaignId,
//   channel,
//   adset,
//   ad,
//   sub1,
//   sub2,
//   sub3,
//   sub4,
//   sub5,
//   deepLink,
//   referrerCustomerId,
//   referrerCustomerUid,
//   forceDeeplink,
// }: {
//   mediaSource?: string
//   campaign?: string
//   campaignId?: string
//   channel?: string
//   adset?: string
//   ad?: string
//   deepLink?: string
//   sub1?: string
//   sub2?: string
//   sub3?: string
//   sub4?: string
//   sub5?: string
//   referrerCustomerId?: string
//   referrerCustomerUid?: string
//   forceDeeplink?: boolean
// }): string {
//   // 官方应用市场url
//   const onelink =
//     getUAInfo().os.name === 'iOS'
//       ? 'https://anystories.onelink.me/zUiI?'
//       : 'https://readink.onelink.me/ePxP?'

//   const result =
//     onelink +
//     [
//       kv('pid', mediaSource),
//       kv('c', campaign),
//       kv('af_c_id', campaignId),
//       kv('af_channel', channel),
//       kv('af_adset', adset),
//       kv('af_ad', ad),
//       kv('af_sub1', sub1),
//       kv('af_sub2', sub2),
//       kv('af_sub3', sub3),
//       kv('af_sub4', sub4),
//       kv('af_sub5', sub5),
//       kv('af_dp', deepLink),
//       kv('deep_link_value', deepLink),
//       kv('af_referrer_customer_id', referrerCustomerId),
//       kv('af_referrer_uid', referrerCustomerUid),
//       kv('af_force_deeplink', forceDeeplink?.toString()),
//     ]
//       .filter((i) => !!i)
//       .join('&')

//   return result
// }

export function generateUrl({
  mediaSource,
  campaign,
  adset,
  ad,
  deepLink,
}: {
  mediaSource?: string
  campaign?: string
  adset?: string
  ad?: string
  deepLink?: string
}): string {
  // 官方应用市场url
  const onelink = 'https://readink.onelink.me/ePxP?'

  const result =
    onelink +
    [
      kv('af_xp', 'custom'),
      kv('pid', mediaSource),
      kv('c', campaign),
      kv('af_adset', adset),
      kv('af_ad', ad),
      kv('af_dp', deepLink),
      kv('af_ad', ad),
      kv('deep_link_value', deepLink),
      kv('deep_link_sub1', deepLink),
    ]
      .filter((i) => !!i)
      .join('&')

  return result
}

function kv(key: string, value: string): string {
  return value ? `${key}=${encodeURIComponent(value)}` : undefined
}
